var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showStues
    ? _c(
        "div",
        { staticClass: "head_nav" },
        [
          _c(
            "yd-navbar",
            { class: { header: _vm.daDiShop } },
            [
              _vm.zgyh_show
                ? _c(
                    "div",
                    {
                      staticClass: "home-icon",
                      attrs: { slot: "left" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.goBack()
                        },
                      },
                      slot: "left",
                    },
                    [
                      _c("img", {
                        staticClass: "icon_img",
                        attrs: {
                          src: require("@/assets/icon_go_page@2x.png"),
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.mall_display_only !== 1
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        slot: "right",
                        to: _vm.seaStatus ? "special_my" : "my",
                      },
                      slot: "right",
                    },
                    [
                      _vm.$route.name === "my"
                        ? _c("icon-svg", {
                            staticClass: "main-color",
                            attrs: { "icon-class": "yi_ic_my_blue_" },
                          })
                        : _c("icon-svg", {
                            staticClass: "grey",
                            attrs: { "icon-class": "yi_ic_my_grey_" },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("router-link", {
                attrs: { slot: "right", to: "home" },
                slot: "right",
              }),
              _c(
                "router-link",
                { attrs: { slot: "right", to: "home" }, slot: "right" },
                [
                  _c("img", {
                    staticClass: "icon_img",
                    attrs: {
                      src: require("@/assets/icon_go_home@2x.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c("p", { staticClass: "blank" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }