var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "address-page" },
    [
      _c("HeadNav"),
      _vm.list.length > 0
        ? _c(
            "yd-infinitescroll",
            { ref: "address", attrs: { callback: _vm.getUserAddressList } },
            [
              _c(
                "div",
                {
                  staticClass: "address-list",
                  attrs: { slot: "list" },
                  slot: "list",
                },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "address-item",
                      attrs: { "data-type": _vm.active_item == index ? 1 : 0 },
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: { "data-index": index },
                          on: {
                            "!touchstart": function ($event) {
                              return _vm.touchStart.apply(null, arguments)
                            },
                            "!touchend": function ($event) {
                              return _vm.touchEnd.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "address-box df fdc",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.skip(item)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "person-info df fdr" }, [
                                _vm._v(
                                  "\n              收货人：" +
                                    _vm._s(item.realname) +
                                    "\n              "
                                ),
                                _c("span", { staticClass: "mobile" }, [
                                  _vm._v(_vm._s(item.mobile)),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "收货地址：" +
                                    _vm._s(item.area_string) +
                                    _vm._s(item.address)
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "setting-box border-top-1px df fdr alc jc-sb",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "left-box df fdr alc",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.setDefault(item, index)
                                    },
                                  },
                                },
                                [
                                  index === _vm.current_default
                                    ? _c(
                                        "div",
                                        [
                                          _c("icon-svg", {
                                            staticClass:
                                              "main-color select-item",
                                            attrs: {
                                              "icon-class": "yi_icon_xuanze",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c("div", {
                                        staticClass: "selected-item",
                                      }),
                                  _c("span", [_vm._v("设为默认")]),
                                ]
                              ),
                              _vm.name
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "right-box",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toNewAddress(item)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._t("default"),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "delete",
                          attrs: { "data-id": item.id },
                          on: { click: _vm.deleteItem },
                        },
                        [_vm._v("删除")]
                      ),
                    ]
                  )
                }),
                0
              ),
              _c("span", { attrs: { slot: "doneTip" }, slot: "doneTip" }, [
                _vm._v("-没有更多了-"),
              ]),
            ]
          )
        : _vm._e(),
      _vm.list.length > 0
        ? _c("div", { staticStyle: { height: "1.88rem" } })
        : _vm.is_request && _vm.list.length === 0
        ? _c("div", { staticClass: "empty-box df fdc alc" }, [
            _c("img", { attrs: { src: _vm.emp_img, alt: "emp_img" } }),
            _vm._v("\n    您还没有任何地址哦～\n  "),
          ])
        : _vm._e(),
      _c("ConfirmAlert", {
        attrs: {
          value: _vm.show_toast,
          is_delete: true,
          confirmText: "您确定要删除已选地址吗？",
          confirmBtnText: "确认删除",
        },
        on: { cancel: _vm.cancel, confirm: _vm.confirm },
      }),
      _c(
        "footer",
        {
          staticClass: "goods-btn flex-center",
          on: { click: _vm.toNewAddress },
        },
        [_c("MyButton", { attrs: { title: "新增地址", is_disabled: false } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }