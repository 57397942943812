<template>
  <div v-if="showStues" class="head_nav">
    <yd-navbar :class="{'header': daDiShop}">
      <div class="home-icon" @click.stop="goBack()" slot="left" v-if="zgyh_show">
        <img src="~@/assets/icon_go_page@2x.png" alt class="icon_img" />
      </div>
      <router-link
				v-if="mall_display_only !== 1"
				:to="seaStatus ? 'special_my' : 'my'" slot="right"
			>
        <icon-svg
					v-if="$route.name === 'my'"
          class="main-color"
          icon-class="yi_ic_my_blue_"
        />
        <icon-svg class="grey" v-else icon-class="yi_ic_my_grey_" />
      </router-link>
      <router-link to="home" slot="right"></router-link>
      <router-link to="home" slot="right">
        <img src="~@/assets/icon_go_home@2x.png" alt class="icon_img" />
      </router-link>
    </yd-navbar>
    <p class="blank"></p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { isWeiXin } from "@/utils/common.js";
import { tripartite } from "@/utils/custom";
import { getStore } from "@/utils/common";
export default {
  name: "head_nav",
  data() {
    return {
      showStues: true,
      closeBid: [1436],
      daDiShop: false, //  大地商城
      zgyh_show: true
    };
  },
  computed: {
    ...mapState({
      //商家id
      business_id: state => state.businessInfo.id || "",
      userInfo: state => state.userInfo, //
      seaStatus: state => state.seaStatus,  //  海元素定制商城
			mall_display_only: state => state.businessInfo.mall_display_only
    })
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    closeRk() {
      if (!isWeiXin()) {
        if (this.closeBid.includes(this.business_id)) {
          this.showStues = false;
        }
      }
    },
    // 中行显隐控制
    isZh(){
      if (process.env.VUE_APP_ZHONGHANG) {
	  		let bids = JSON.parse(process.env.VUE_APP_ZHONGHANG);
	  		bids.forEach( val => {
	  			if (val == JSON.parse(getStore('businessInfo')).id) {
	  					this.zgyh_show = false;
	  					return;
	  			}
	  		});
	  	}
    },
  },
  created() {
    this.closeRk();
    if ( tripartite('VUE_APP_DADIBX') ) {
      this.daDiShop = true
    }
    this.isZh()
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/iconfont/iconfont.css";
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.head_nav {
    
  .yd-navbar {
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
    .icon_img {
      width: 0.48rem;
      height: 0.48rem;
    }
    .grey {
      color: #b3b3b3;
      margin-top: 0.06rem;
    }
  }
  .blank {
    height: 0.88rem;
  }
}
</style>
