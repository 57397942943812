<template>
  <div class="save-box">
    <button class="save-btn" :disabled="is_disabled">{{title}}</button>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    is_disabled: Boolean,
  },
}
</script>

<style scoped lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

/*保存按钮*/

.save-box {
  .wh(100%, 0.88rem);
  padding: 0 0.24rem;

  .save-btn {
    .wh(100%, 100%);
    background: var(--main-color);
    border-radius: 1rem;
    line-height: 0.88rem;
    .sc(0.32rem, #fff);
    text-align: center;
    border: 0;
  }

  button[disabled] {
    opacity: 0.4;
  }
}
</style>