<template>
  <div class="address-page">
    <HeadNav/>
    <yd-infinitescroll v-if="list.length>0" :callback="getUserAddressList" ref="address">
      <div class="address-list" slot="list">
        <div
          class="address-item"
          v-for="(item,index) in list"
          :data-type="active_item == index ? 1 : 0"
          :key="index"
        >
          <div @touchstart.capture="touchStart" :data-index="index" @touchend.capture="touchEnd">
            <div class="address-box df fdc" @click.stop="skip(item)">
              <div class="person-info df fdr">
                收货人：{{item.realname}}
                <span class="mobile">{{item.mobile}}</span>
              </div>
              <div>收货地址：{{item.area_string}}{{item.address}}</div>
            </div>
            <div class="setting-box border-top-1px df fdr alc jc-sb">
              <div class="left-box df fdr alc" @click.stop="setDefault(item,index)">
                <div v-if="index===current_default">
                  <icon-svg class="main-color select-item" icon-class="yi_icon_xuanze" />
                </div>
                <div v-else class="selected-item"></div>
                <span>设为默认</span>
              </div>
              <div v-if="name" class="right-box" @click="toNewAddress(item)">编辑</div>
            </div>
            <slot></slot>
          </div>
          <div class="delete" @click="deleteItem" :data-id="item.id">删除</div>
        </div>
      </div>
      <!-- 数据全部加载完毕显示 -->
      <span slot="doneTip">-没有更多了-</span>
    </yd-infinitescroll>
    <div style="height: 1.88rem;" v-if="list.length>0"></div>
    <!--地址为空-->
    <div class="empty-box df fdc alc" v-else-if="is_request&&list.length===0">
      <img :src="emp_img" alt="emp_img" />
      您还没有任何地址哦～
    </div>
    <ConfirmAlert
      :value="show_toast"
      :is_delete="true"
      confirmText="您确定要删除已选地址吗？"
      confirmBtnText="确认删除"
      @cancel="cancel"
      @confirm="confirm"
    ></ConfirmAlert>
    <footer class="goods-btn flex-center" @click="toNewAddress">
      <MyButton title="新增地址" :is_disabled="false"></MyButton>
    </footer>
  </div>
</template>

<script>
import MyButton from "@/components/button/button";
import ConfirmAlert from "@/components/common/ConfirmAlert";
import HeadNav from "@/components/common/HeadNav";
import {
  getUserAddressList,
  deleteUserAddress,
  editUserAddress,
  receiveAward
} from "@/services/my";
import { mapState } from "vuex";

export default {
  name: "AddressList",
  components: {
    MyButton,
    ConfirmAlert,
    HeadNav
  },
  data() {
    return {
      page: 1, // 开始查询的下标
      listRows: 10, // 每次查询的数量
      list: [], //地址列表
      emp_img: require("@/assets/img_location_blank@3x.png"),
      startX: 0, //滑动开始
      endX: 0, //滑动结束
      active_item: -1, //当前活动的滑块
      show_toast: false, //删除确认弹窗
      del_id: "", //删除的id
      current_default: -1, //当前默认地址
      name: "", //从哪里来
      is_request: 0
    };
  },
  computed: {
    ...mapState({
      //默认地址
      user_address_id: state =>
        state.userInfo.user_address_id ? state.userInfo.user_address_id : ""
    })
  },
  created() {
    this.name = this.$route.query.name ? this.$route.query.name : "";
  },
  mounted() {
    this.getUserAddressList();
  },
  methods: {
    async getUserAddressList() {
      let params = {
        page: this.page,
        listRows: this.listRows
      };
      try {
        const res = await getUserAddressList(params);
        this.is_request = 1;
        if (res.code === 0) {
          const data = res.data;
          console.log(data);

          this.list = [...this.list, ...data.list];
          this.total = data.total;
          if (this.list.length > 0) {
            this.list.filter((item, index) => {
              if (item.is_default === 1) {
                this.current_default = index;
                this.$store.dispatch("getUserAddressDetail", item.id);
              } /* else {
                this.$store.dispatch("getUserAddressDetail", this.list[0].id);
              } */
            });
          } else if (this.list.length === 0) {
            this.$store.commit("clearAddress");
          }

          if (this.list.length === data.total) {
            /* 所有数据加载完毕 */
            this.page++;
            this.$refs.address.$emit("ydui.infinitescroll.loadedDone");
            return;
          }
          /* 单次请求数据完毕 */
          this.page++;
          this.$refs.address.$emit("ydui.infinitescroll.finishLoad");
        }
      } catch (e) {}
    },
    //跳转
    skip(item) {
      console.log(this.name === 'turntable')
      if (this.checkSlide()) {
        this.active_item = -1;
      } else {
        if (this.name === 'order') {
          //从订单确认来
          this.$router.go(-1);
          this.$store.dispatch("chooseAddress", item);
        } else if (this.name === 'turntable') {
          this.receiveAward(item)
          // this.$router.go(-1);
        } else {
          this.$router.push({
            path: "/new_address",
            query: {
              edit_id: item.id
            }
          });
        }
      }
    },
    async receiveAward(item) {
      try {
        let params = {
          user_address_id: item.id,
          draw_log_id: this.$route.query.id || 0
        }
        const res = await receiveAward(params)
        if (res.code === 0) {
          this.$dialog.toast({
            mes: "奖品领取成功，请注意查收",
            timeout: 1500
          });
          this.$router.go(-1);
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: 'error',
            timeout: 1500
          });
          this.$router.go(-1);
        }
      } catch(e) {}
    },
    //滑动开始
    touchStart(e) {
      this.startX = e.touches[0].clientX;
    },
    //滑动结束
    touchEnd(e) {
      this.endX = e.changedTouches[0].clientX;
      if (this.startX - this.endX > 30) {
        this.active_item = e.currentTarget.dataset.index;
      }
      if (this.startX - this.endX < -30) {
        this.active_item = -1;
      }
      this.startX = 0;
      this.endX = 0;
    },
    //判断当前是否有滑块处于滑动状态
    checkSlide() {
      return this.active_item > -1;
    },
    //删除
    deleteItem(e) {
      this.show_toast = true;
      this.del_id = e.currentTarget.dataset.id;
    },
    // 点击取消按钮
    cancel() {
      this.active_item = -1;
      this.show_toast = false;
    },
    // 点击确认按钮
    async confirm() {
      this.active_item = -1;
      this.show_toast = false;
      try {
        const res = await deleteUserAddress(this.del_id);
        if (res.code === 0) {
          this.$dialog.toast({
            mes: "删除成功",
            icon: "success",
            timeout: 1500
          });
          this.list = [];
          this.page = 1;
          this.getUserAddressList();
        }
      } catch (e) {}
    },
    //设为默认
    async setDefault(item, index) {
      console.log(item);
      item.is_default = 1;
      try {
        const res = await editUserAddress(item);
        if (res.code === 0) {
          this.$dialog.toast({
            mes: "设为默认地址成功",
            icon: "success"
          });
          this.current_default = index;
        }
      } catch (e) {}
    },
    toNewAddress(item) {
      this.$router.push({
        path: "/new_address",
        query: {
          edit_id: item.id
        }
      });
    }
  }
};
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.address-page {
  /*地址列表*/

  .yd-list-donetip {
    .sc(0.28rem, @color-light-grey);
  }

  .address-list {
    width: 100%;
    overflow: hidden;
  }

  .address-item {
    width: 100%;
    background: @color-white;
    padding: 0 0.24rem;
    margin-bottom: 0.2rem;
    position: relative;
    transition: all 0.2s;

    &[data-type="0"] {
      transform: translate3d(0, 0, 0);
    }

    &[data-type="1"] {
      transform: translate3d(-1.8rem, 0, 0);
    }

    .address-box {
      min-height: 1.96rem;
      padding: 0.24rem 1.44rem 0.24rem 0;
      line-height: 0.44rem;
      .sc(0.28rem, @color-dark-grey);
      background: white url(../../../assets/icon_more@3x.png) no-repeat 100% 50%;
      background-size: 0.16rem 0.26rem;

      .person-info {
        margin-bottom: 0.24rem;
        .sc(0.32rem, @color-dark-grey);

        .mobile {
          margin-left: 0.24rem;
        }
      }
    }

    /*是否默认*/

    .setting-box {
      height: 0.84rem;

      .left-box {
        width: 50%;

        .select-item {
          .wh(20px, 20px);
        }

        .selected-item {
          .wh(20px, 20px);
          border: 1px solid @color-light-grey;
          border-radius: 50%;
        }

        span {
          .sc(0.28rem, @color-dark-grey);
          line-height: 0.4rem;
          margin-left: 0.24rem;
        }
      }

      .right-box {
        .wh(1.8rem, 0.6rem);
        line-height: 0.6rem;
        text-align: center;
        .sc(0.28rem, var(--main-color));
        border: 0.02rem solid var(--main-color);
        border-radius: 0.48rem;
      }
    }

    /*删除按钮*/

    .delete {
      .wh(1.8rem, 100%);
      background: @color-add-red;
      .sc(0.28rem, @color-white);
      line-height: 2.82rem;
      text-align: center;
      position: absolute;
      top: 0;
      right: -1.82rem;
      z-index: 3;
    }
  }

  /*新增按钮*/

  footer {
    .wh(100%, 1.12rem);
    background: @color-white;
    position: fixed;
    bottom: 0;
    z-index: 3;
  }

  /* 列表为空*/

  .empty-box {
    background: @color-white;
    height: 100vh;

    img {
      .wh(3.64rem, 2.58rem);
      margin-top: 1.76rem;
      margin-bottom: 0.74rem;
    }

    .sc(0.28rem, @color-light-grey);
  }
}
</style>
